*
  box-sizing: border-box

body
  font-size: 16px
  color: #000
  background-color: #f9f5f4
  min-width: 320px
  font-family: 'Inter', sans-serif
  font-weight: 400
  overflow-x: hidden

.container
  margin: 0 auto
  padding: 0 1rem
  max-width: 104rem

.container__title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2rem
  h1
    margin-top: 0

h1
  font-weight: 800
  font-size: 1.5rem
  margin-top: 2rem
h2
  margin-top: 1rem
  font-weight: 800
  font-size: 1.4rem

@media (max-width: 768px)
  .container__title
    display: block
    text-align: center
    h1
      display: block
      margin-bottom: 1rem