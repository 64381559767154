.not-found
  margin-top: 2rem
  h1
    text-transform: uppercase
    position: relative
    z-index: 1
    width: 100%
    height: 3rem
    background-color: #000
    color: #f9f5f4
    padding: 0.4rem 0
    transform-origin: top
    transform: scaleY(4)
    font-weight: 800
    font-size: 0
    span
      font-size: 1.5rem
      position: absolute
      top: -4px
      left: -2px
      display: inline-block
      transform-origin: left
      transform: scaleX(2)
      max-width: 50%

@media (max-width: 768px)
  .not-found
    h1
      font-size: 0
      span
        font-size: 1rem
        top: -3px
        left: -3px
