.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 1rem
  margin-bottom: 2rem

  .socials
    margin-bottom: 16px
    margin-right: 1rem
    a
      text-decoration: none
      border-bottom: 5px solid #000
      color: #000
      font-size: 1.5rem
      padding: 0.5rem 0.4rem 0 0.4rem
      z-index: 1
      position: relative
      &::before
        content: ''
        position: absolute
        height: 0%
        bottom: 0
        left: 0
        right: 0
        background-color: #000
        z-index: -1
        transition: 0.2s height ease
      &:hover
        color: #fff
        &::before
          height: 100%

  ul
    background-color: #fff
    height: 5rem
    display: flex
    align-items: center
    padding: 0 2rem
    margin: -1rem 0
    li
      display: inline-block
      a
        text-decoration: none
        color: #000
        font-weight: 700
        text-transform: lowercase
        font-size: 1rem
        letter-spacing: -1px
        position: relative
        padding: 0 0.5rem
        &:before
          content: ''
          position: absolute
          bottom: -10px
          left: 0
          width: 100%
          height: 4px
          background-color: transparent
          transition: 0.1s ease background-color
          pointer-events: none
        &:hover:before
          background-color: #000
      &+li
        margin-left: 1rem

  .logo
    position: relative
    text-decoration: none
    span
      font-weight: 700
      color: #000
      font-size: 2rem
      letter-spacing: -0.3125rem
      z-index: 1
      transition: 0.1s ease transform
      text-transform: lowercase
    span:nth-of-type(2)
      position: absolute
      top: 0
      color: #5dcffc
      z-index: -1
      left: 2px
      transform-origin: left
    span:nth-of-type(3)
      position: absolute
      top: 0
      color: #56fd94
      z-index: -2
      left: 4px
      transform-origin: left
    &:hover
      span:nth-of-type(2)
        // left: 4px
        transform: scaleX(1.1)
      span:nth-of-type(3)
        // left: 6px
        transform: scaleX(1.2)

@media (max-width: 768px)
  .header
    padding: 0.5rem
    flex-wrap: wrap
    justify-content: center
    .logo
      order: 1

    ul
      width: 100%
      order: 3
      height: 2rem
      padding: 0 1rem
      margin-top: 0
      justify-content: center
      background-color: transparent
      li
        a
          font-size: 0.9rem
        &+li
          margin-left: 0.5rem

    .socials
      order: 2
      margin-left: 2rem
      margin-top: 1rem