.movies
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  .movie
    width: 26rem
    height: 35rem
    background: #fff
    position: relative
    display: flex
    flex-direction: column
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
    margin-bottom: 4rem
    .movie__info
      height: 40%
      padding: 1rem 1rem 5rem 1rem
      align-items: center
      display: flex
      flex-direction: column
      justify-content: center
    .movie__ru
      line-height: 1
      font-size: 1.2rem
      font-weight: 800
      text-transform: uppercase
      color: #000
      text-align: center
    .movie__title
      z-index: 2
      display: inline-block
      overflow: hidden
      padding-right: 0.5rem
      position: relative
      text-align: center
      padding: 0 1rem 0 0.4rem
      margin-top: 0.5rem
      span
        font-size: 1.8rem
        line-height: 1
        font-weight: 800
        text-transform: uppercase
      span:nth-of-type(1)
        color: #000
        z-index: 1
      span:nth-of-type(2), span:nth-of-type(3)
        position: absolute
        width: calc(100% - 1.4rem)
        height: 100%
        top: 0
        left: 9px
        color: #000
        z-index: -1
        pointer-events: none
      span:nth-of-type(2)
        left: 10px
        color: #5dcffc
        z-index: -2
      span:nth-of-type(3)
        left: 12px
        color: #56fd94
        z-index: -3
    .movie__img-container
      height: 60%
      width: 100%
      position: relative
    .movie__img
      object-position: center
      object-fit: cover
      height: 100%
      width: 100%
      filter: grayscale(70%)
      transition: 0.2s ease filter
    &:focus
      outline: 2px solid #000
    &:hover, &:focus
      .movie__img
        filter: grayscale(0%)
    .movie__director
      position: absolute
      bottom: 2.5rem
      left: 0
      right: 0
      text-align: center
      color: #000
      font-weight: 600
      font-size: 1rem
    .movie__year
      position: absolute
      bottom: 1rem
      left: 0
      right: 0
      text-align: center
      color: #000
      font-weight: 400
      font-size: 1rem

@media (max-width: 84rem)
  .movies
    .movie
      width: 21.25rem
      height: 28.625rem
      .movie__info
        padding-bottom: 3.5rem
      .movie__ru
        font-size: 1rem
      .movie__title
        margin-top: 0.2rem
        span
          font-size: 1.4rem
      .movie__director
        font-size: 0.9rem
        bottom: 2.1rem
      .movie__year
        font-size: 0.9rem

@media (max-width: 68.75rem)
  .movies
    .movie
      width: 44%
      height: 28.625rem

@media (max-width: 48rem)
  .movies
    .movie
      width: 100%
      height: 28.625rem